import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import Select from 'react-select'

export default function DoctorDictionarySelector(props) {
    const {
        loading = false,
        items = [],
        onChange = v => {

        }
    } = props;

    // let {currentUserObj} = useMappedState(useCallback(state => {
    //     return {
    //         currentUserObj: state.auth.currentUserObj
    //     }
    // }, []));
    // let currentUUID = currentUserObj?.healthUser?.uuid;

    let options = items.map(x => ({value: x.id, label: x.name}));
    let selectedOption = options.filter(x => (x.value == props.dataId))[0];

    // console.log('DoctorDictionarySelector: render: props = ', props);

    return (
        <Wrapper>

            <Select
                options={options}
                isClearable
                isLoading={loading}
                value={selectedOption}
                onChange={a => {
                    if (a == undefined) {
                        onChange(undefined);
                    } else {
                        let x = items.filter(xx => (xx.id == a.value))[0];
                        onChange(x);
                    }
                }}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;
import styled from "styled-components";
import xImage from "../../assets/images/x.svg";

export default function NiceModal(props) {
  const { onClose = () => {}, zIndex = 10, styles = {} } = props;

  return (
    <Wrapper $zIndex={zIndex}>
      <Box className="alarm-range-placeholder" style={{ ...styles }}>
        <TopBox className={"no-print"}>
          <CloseIcon
            src={xImage}
            onClick={() => {
              onClose();
            }}
          />
        </TopBox>

        <ContentPlaceholder>{props.children}</ContentPlaceholder>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: ${(props) => (props.$zIndex == undefined ? 10 : props.$zIndex)};
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(51, 51, 51, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
`;

const Box = styled.div`
  box-sizing: border-box;
  background: white;
  position: relative;
  border-radius: 4px;
  padding: 30px;
`;

const TopBox = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ContentPlaceholder = styled.div``;
